import { getInitialState, buildHandlers } from 'redux/lib'
import { createReducer } from 'redux-immutablejs'
import {
  // actions creators
  accountActions,
  alcoholsActions,
  amenitiesActions,
  currentUsersActions,
  eventsActions,
  filesActions,
  foodsActions,
  hostApplicationsActions,
  invitesActions,
  partnersActions,
  placesActions,
  requestsActions,
  schedulesActions,
  tripAdvisorUsersActions,
  usersActions,

  // additional handlers
  accountCustomHandlers,
  appSettingsCustomHandlers,
  eventsCustomHandlers,
  modalCustomHandlers,
  placesCustomHandlers,
} from 'redux/actions'

const actionsCreators = {
  accountActions,
  alcoholsActions,
  amenitiesActions,
  currentUsersActions,
  eventsActions,
  filesActions,
  foodsActions,
  hostApplicationsActions,
  invitesActions,
  partnersActions,
  placesActions,
  requestsActions,
  schedulesActions,
  tripAdvisorUsersActions,
  usersActions,
}

const additionalHandlers = {
  ...accountCustomHandlers,
  ...appSettingsCustomHandlers,
  ...eventsCustomHandlers,
  ...modalCustomHandlers,
  ...placesCustomHandlers,
}

export default createReducer(
  getInitialState({ options: { metadata: true } }),
  buildHandlers({ actionsCreators, additionalHandlers }),
)
